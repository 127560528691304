.App {
  text-align: center;
}

.container.d-flex.align-items-center.flex-column {
  margin-top: 1em;
}

.center-image {
  margin-bottom: 1em;
}

.alert {
  font-family: 'GOTHIC';
  margin-bottom: 0.3em;
  padding-bottom: 0;
}

.alert-message {
  font-size: 0.7em;
}

.title
{
  color: #4203BF;
  margin-bottom: 0.5em;
}

.general-form {
  font-size: 1.5em;
}

.field-form {
  font-family: 'GOTHICB';
  text-align: center;
  display: flex;
  flex-direction: column;
}

.right.link
{
  float: right;
  font-size: 0.7em;
}

.form-group {
  font-family: 'GOTHIC';
  font-size: calc(10px + 1vmin);
  text-align:left;
  margin-bottom: 0.5em;
}

form {
  width: auto;
  align-self: center;
}

.simple-text {
  font-family: 'GOTHIC';
  font-size: 0.7em;
  margin-bottom: 0;
}

.simple-text > a {
  font-family: 'GOTHICB';
  color: #4203BF;
}

.bootstrap-dropdown {
  height: calc(1.5em + .75rem + 2px);
  text-align:left;
  color: grey;
  font-size: 0.9em;
  background-color: white;
  border-color: #ced4da ;
}

.submission-status {
  font-family: 'GOTHICB';
}

.photo {
  position: absolute;
  height: 25em;
  width: 15em;
  z-index: -1;
}

.photo1 {
  left: 7em;
  top: 7em;
}

.photo2 {
  right: 9em;
  top: 5em;
  rotate: 180deg;
}

.spinner-container {
  margin-top: 25em;
  text-align: center !important;
}

.spinner-border {
  color: #4203BF;
}

.changeWholeColorButton .toggle-switch {
  width: 100px;
  display: inline-block;
  text-align: left;
  top: 75px;
  position: fixed;
  right: 0px;
}
.changeWholeColorButton .checkbox {
  display: none;
}
.changeWholeColorButton .label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.changeWholeColorButton .inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.changeWholeColorButton .inner:before,
.changeWholeColorButton .inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.changeWholeColorButton .inner:before {
  content: "Vivid";
  padding-left: 10px;
  background-color: #4203BF;
  color: #fff;
}
.changeWholeColorButton .inner:after {
  content: "Calm";
  padding-right: 10px;
  background-color: #23358F;
  color: #fff;
  text-align: right;
}
.changeWholeColorButton .switch {
  display: block;
  width: 30px;
  margin: 3px 4px 2px 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 63px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all .3s ease-in 0s;
  height: 30px;
}
.changeWholeColorButton .Dark .switch {
  background: #f5f5f5;
}
.changeWholeColorButton .checkbox:checked + .label .inner {
  margin-left: 0;
}
.changeWholeColorButton .checkbox:checked + .label .switch {
  right: 0px;
}
/*.EditorToolbar__root___3_Aqz{
  position: relative;
  z-index: 0;
}
.RichTextEditor__root___2QXK-{
  position: relative;
  z-index: 0;
}*/
.sections.form-margin {
  position: relative;
  z-index: 0;
}

.table td, .table th {
  max-width: 216px;
  white-space: normal;
  word-break: break-word;
}

.headingTitle {
  max-width: 450px;
  display: inline-block;
  word-wrap: break-word;
  margin: 0px auto;
}
.Item_item__34h1P{
  white-space: normal;
}
table.table.table-striped{
  text-align: left;
}
.RichTextEditor__root___2QXK- {
  font-family: "century gothic" !important;
} 
form .error{
  color:#ff0000;
}