.job-application-submission {
    font-family: 'GOTHICB';
    font-size: 1.5em;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 36em;
    border: 5px solid rgb(66, 3, 191, 0.35);
    border-radius:20px;
  }
 
  .title-job {
      margin-top: 0.2em;
      margin-bottom: 0.5 !important;
  }

  .title-container {
    margin: 0.5em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .react-datepicker__input-container {
    width: inherit;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .job-submission-status
  {
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #4203BF;
  }

  .buttons
{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}
  
  .button-job {
    margin: 0.5em 1em !important;
  }
  
  .section
  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;
    align-content: space-between;
    justify-content: space-between;
  }

  .form-margin {
    margin-left: 2em;
    margin-right: 2em;
  }
  
  .subsections
  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 13em;
  }
  
  .dropdown-margin {
    margin-bottom: 8px;
    width: 100%;
  }

  .about-team-label {
    margin-bottom: 0;
  }

  @media all and (max-width: 1023px){
    .creatjoblistingform { 
      width: 100%;
     }
      .creatjoblistingform .form-margin {
        margin-left: 12px;
        margin-right: 12px;
    }
  }

  @media all and (max-width: 767px){
    
  .creatjoblistingform { 
    width: 100%;
   }
   .creatjoblistingform .subsections {
    width: 100%;
    }
    .creatjoblistingform  h1.title-job {
      font-size: 2rem;
    }
    .creatjoblistingform .form-margin {
      margin-left: 1em;
      margin-right: 1em;
    }

  }