.user-request-submission {
    font-family: 'GOTHICB';
    font-size: 1.5em;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 36em;
    border: 5px solid rgb(66, 3, 191, 0.35);
    border-radius:20px;
  }

  .title-job {
      margin-top: 0.2em;
      margin-bottom: 0.5 !important;
  }

  .title-container {
    margin: 0.5em 0;
    display: flex;
    justify-content: space-around;
  }
  
  .job-submission-status {
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #4203BF;
  }

  .buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}
  
  .button-job {
    margin: 0.5em 1em !important;
  }
  
  .user-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 12em;
    align-content: space-between;
  }

  .form-margin {
    margin-left: 2em;
    margin-right: 2em;
  }
  
  .subsections {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 13em;
  }

  .my-checkbox {
    margin-top:0.5em;
  }