.align-left {
    font-family: 'GOTHICB';
    font-size: 1.5em;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: auto;
    color: #4203BF;
}

.title-candidate {
    display: inline-block;
    margin-right: 25em;
    margin-left: 0 !important;
}

.smaller-title {
    font-family: 'GOTHIC';
    font-size: 1em;
}

.download-button-text {
    color: black;
    font-size: calc(10px + 1vmin);
}

.consenting-candidates {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.consenting-candidate {
    font-family: 'GOTHIC' !important;
    font-size: calc(10px + 1vmin);
    background-color: #8913b0;
    border-radius: 20px;
    color: white;
    width: 30em;
    height: 4em;
    margin: 0.5em 0.5em;
    padding-left: 0.5em;
    padding-top: 0.5em;
    text-align: left !important;
}