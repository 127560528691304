.my-profile {
    font-family: 'GOTHICB';
    font-size: 1.5em;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: auto;
    height: 30em !important;
    border: 5px solid rgb(66, 3, 191, 0.35);
    border-radius:20px;
}

.title-job {
    margin-top: 0.2em;
    margin-bottom: 0.5 !important;
    margin-left: 0.5em !important;
    text-align: left !important;
}

.small-title {
    font-family: 'GOTHICB';
    font-size: 1em;
    color: #4203BF;
    margin-left: 0.5em
}

.title-container-profile {
    margin: 0.5em 0;
    display: flex;
    flex-direction: left;
}

.section-profile {
    font-family: 'GOTHIC';
    color: #4203BF;
    display: flex;
    flex-direction: column;
    align-content: left;
    margin: 1em 0.5em;
}

.data-field {
    color: #f121bd;
}