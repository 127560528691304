.navbar {
    background-color: white;
}

.center-image{
    display:flex;
    justify-content:center; /* horizontally center */
    align-items:center;    /* vertically center */
    margin-top: 1em;
  }
  
.resources-dropdown {
    background-color: white;
    border: 0em;
    color: rgba(0,0,0,.5);
    padding-left: .5rem;
    font-family: 'GOTHIC';
}

.dropdown-link {
    color:black !important;
    text-decoration: underline;
}

a {
    font-family: 'GOTHIC';
}

.dropdown-item {
    font-family: 'GOTHIC';
    color:black !important;
}

.dropdown-item > div {
    min-width: 290px !important;
}

.item-hover:hover  {
    background-color:rgb(66, 3, 191, 0.20) !important;
}

.faq-management-dropdown {
    background-color: white;
    border: 0em;
    color: rgba(0,0,0,.5);
    padding-left: 0.5rem;
    font-family: 'GOTHIC';
}