.headingTitle{
    font-size:2em;
    padding: 20px 0px;
}
.faqs-application-submission {
    font-family: "GOTHICB";
    font-size: 1.5em;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 36em;
    border: 5px solid rgba(66,3,191,.35);
    border-radius: 20px;
}
td.pageContentColumn p {
    margin-top: 0;
    margin-bottom: 0rem;
    text-align:left;
}
.actionButtonSection{
    display: inline-flex;
}
button.add_faq,button.view_faq_categories,button.faq_question {
    width: auto;
    margin-bottom: 10px;
    background: #8913b0;
    margin-right: 10px;
}
.title{
    font-size: 1.2em;
}
.faqs-application-submission h1.title.title-job {
    max-width: 600px;
    white-space: normal;
    word-break: break-word;
    text-align: center !important;
    font-size: 22px;
}
.btn.button1{
    height: 40px;
    line-height: 26px;
}