.account-state {
  font-family: 'GOTHIC';
}

.form-control {
  font-family: 'GOTHIC';
}

form .company-name {
  margin-bottom: 0em;
}

.add-employer-form {
  border: 5px solid rgb(66, 3, 191, 0.35);
  border-radius:20px;
  padding: 1em;
}