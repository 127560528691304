.account-state {
  font-family: 'GOTHIC';
}

.show > .dropdown-menu {
  display: block;
  position: absolute;
}

.employer-dropdown > button {
  overflow: hidden;
  padding-right: 24px;
  text-align: left;
  text-overflow: ellipsis;
}

.employer-dropdown > * {   
  width: 100%;
}

.dropdown-toggle::after {
  position: absolute;
  right: 12px;
  top: calc(50% - 2px);
}

.form-control {
  font-family: 'GOTHIC';
}

.company-name {
  margin-bottom: 0.7em;
}

.add-admin-form {
  border: 5px solid rgb(66, 3, 191, 0.35);
  border-radius:20px;
  padding: 1em 2em;
}