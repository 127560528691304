.column {
    float: left;
    margin-left: 5em;
    margin-right: 5em;
}

.column-title {
    font-size: 1.2em;
    text-align: left;
}

.row {
    margin-top: 1em;
    margin-bottom: 2em;
    width: max-content;
}

.click-job {
  color: white;
  width: 100%;
  height: 100%;
}

.click-job:hover {
  color: white;
}

.job-list {
    font-family: "GOTHIC";
    font-size: 1em;
    border: 0.05em solid #8913b0;
    border-radius: 20px;
    width: 28em;
    /* height: 17em; */
    min-height: -webkit-min-content;
    min-height: min-content;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    padding: 15px 10px 15px 15px;
  }

  .job-container {
    color: #fff;
    background-color: #8913b0;
    border-radius: 10px;
    padding: 10px 1em;
    margin: 0.5em 15px 0.5em 0;
  }

  .job {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    text-align: left;
  }

  .job-bottom {
      display: flex;
      justify-content: space-between;
  }

  .download-report {
    float: right;
    font-family: 'GOTHIC';
  }