.login {
  font-family: 'GOTHICB';
    font-size: 1.5em;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.right.link
{
  float: right;
  font-size: 0.7em;
}

.title
{
  color: #4203BF;
  margin-bottom: 0.5em;
}

.passwordHelp {
  color: #4203BF;
}

.registerMessage {
  font-family: 'GOTHIC';
  font-size: 0.7em;
}

.registerMessage> a {
  font-family: 'GOTHICB';
  color: #4203BF;
}

.btn.button1
{
  font-family: 'GOTHIC';
  color: white;
  margin: 1em 0.5em;
  background-color: #8913b0;
  border-color: #8913b0;
}

.btn.button2
{
  font-family: 'GOTHIC';
  color: white;
  margin-bottom: 1em;
  background-color: #f121bd;
  border-color: #f121bd;
}

.btn.button3
{
  font-family: 'GOTHIC';
  color: black;
  margin-bottom: 1em;
  background-color: white;
  border-color: white;
}