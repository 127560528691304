.employer-user-box {
    justify-content: space-between;
    padding-right: 0.5em;
}

.user-bottom {
    display: flex;
    justify-content: space-between;
}

.simple-text.delete-link {
    color:white;
}