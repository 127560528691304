.headingTitle{
    font-size:2em;
    padding: 20px 0px;
}
.content-pages-application-submission {
    font-family: "GOTHICB";
    font-size: 1.5em;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 36em;
    border: 5px solid rgba(66,3,191,.35);
    border-radius: 20px;
}
td.pageContentColumn p {
    margin-top: 0;
    margin-bottom: 0rem;
    text-align:left;
}