.page {
  width: 100%;
}

.table-dark {
  background-color: #8913b0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: white;
}

.table-div {
 height: 30em;
 overflow-x: hidden;
 overflow-y: auto;
}

.top-control {
  width: 100%;
}

.report-company-name {
  display: flex;
  flex-grow: 1;
}

.label-class {
  margin-right: 0.5em;
  padding-top: 0.2em;
}

.report-dropdown > button {
  overflow: hidden;
  padding-right: 30px;
  text-align: left;
  text-overflow: ellipsis;
}